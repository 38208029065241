import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import styled, { css } from 'styled-components'

// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.3s ease;
  pointer-events: none;

  ${props =>
    props.visible &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
      pointer-events: auto;
    `}
`

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(50px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;

  ${props =>
    props.visible &&
    css`
      transform: translateY(0);
      opacity: 1;
    `}
`

const ModalContent = styled.div`
  margin-bottom: 20px;
`

const ModalHeading = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`

const CloseButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  &:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
`

const InfoModal = ({ content }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [canClose, setCanClose] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const COOKIE_NAME = 'info-modal-shown'
  const SESSION_KEY = 'modal-shown-this-session'

  // Function to disable/enable scrolling
  const toggleScrollLock = lock => {
    if (typeof document !== 'undefined') {
      if (lock) {
        // Save current scroll position
        const scrollY = window.scrollY
        document.body.style.position = 'fixed'
        document.body.style.top = `-${scrollY}px`
        document.body.style.width = '100%'
        document.body.style.overflowY = 'scroll'
      } else {
        // Restore scroll position
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.width = ''
        document.body.style.overflowY = ''
        window.scrollTo(0, Number.parseInt(scrollY || '0') * -1)
      }
    }
  }

  useEffect(() => {
    // Check if we should show the modal
    const shouldShowModal = () => {
      // First check permanent cookie (don't show again)
      if (Cookies.get(COOKIE_NAME)) {
        return false
      }

      // Then check sessionStorage (for current browsing session)
      if (typeof window !== 'undefined' && window.sessionStorage.getItem(SESSION_KEY)) {
        return false
      }

      // If neither exists, we should show the modal
      return true
    }

    if (shouldShowModal()) {
      // Show modal after 2 seconds
      const showTimer = setTimeout(() => {
        setIsAnimating(true)
        // Start animation
        setTimeout(() => {
          setIsVisible(true)
          toggleScrollLock(true) // Lock scrolling
        }, 50)

        // Enable close button after 3 seconds
        const closeTimer = setTimeout(() => {
          setCanClose(true)
        }, 3000)

        return () => clearTimeout(closeTimer)
      }, 2000)

      return () => clearTimeout(showTimer)
    }
  }, [])

  const handleClose = () => {
    // Start closing animation
    setIsAnimating(false)

    // Wait for animation to complete before fully removing
    setTimeout(() => {
      setIsVisible(false)
      toggleScrollLock(false) // Unlock scrolling
    }, 300) // Match this with the CSS transition duration

    // Always set sessionStorage to prevent showing again during this session
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(SESSION_KEY, 'true')
    }

    // If "don't show again" is checked, set a permanent cookie
    if (dontShowAgain) {
      Cookies.set(COOKIE_NAME, 'true', { expires: 30, path: '/' })
    }
  }

  if (!isAnimating && !isVisible) return null

  return (
    <ModalOverlay visible={isVisible}>
      <ModalContainer visible={isVisible}>
        <ModalContent>
          {content || (
            <>
              <ModalHeading>Ważna informacja</ModalHeading>
              <p>Informujemy, że od 1 kwietnia zmiana lokalizacji firmy.</p>
              <p style={{ fontWeight: '600' }}>Nowy adres: ul. Podleśna 1A, 98-100 Łask</p>
              <br />
              <a
                style={{ color: 'black' }}
                href='https://www.google.com/maps/place/JM+Car+Company+-+samochody+nowe+i+u%C5%BCywane/@51.595301,19.1612945,17z/data=!3m1!4b1!4m6!3m5!1s0x471a47acb38172d3:0xa9634349ed251967!8m2!3d51.595301!4d19.1638694!16s%2Fg%2F11h31x53vz?entry=ttu&g_ep=EgoyMDI1MDQwNi4wIKXMDSoASAFQAw%3D%3D'
                rel='noopener noreferrer'
                target='_blank'
              >
                Sprawdź na mapie
              </a>
            </>
          )}
          <ModalFooter>
            <CheckboxContainer>
              <input type='checkbox' checked={dontShowAgain} onChange={() => setDontShowAgain(!dontShowAgain)} />
              <span>Nie pokazuj ponownie</span>
            </CheckboxContainer>

            <CloseButton onClick={handleClose} disabled={!canClose}>
              {!canClose ? 'Zamknij (dostępne za 3s)' : 'Zamknij'}
            </CloseButton>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  )
}

export default InfoModal
